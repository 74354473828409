<script setup lang="ts">
import {onClickOutside, useFocus, useMagicKeys} from "@vueuse/core";
import {
    AisInstantSearch,
    AisHits,
    AisStats,
    AisConfigure,
    AisIndex
} from 'vue-instantsearch/vue3/es';
import useAlgolia from "~/composables/useAlgolia";
import LayoutCategorySuggestSearch from "~/components/layout/LayoutCategorySuggestSearch.vue";
import ProductSuggestSearch from "~/components/product/ProductSuggestSearch.vue";
import useCustomSearchVisibility from "~/composables/useCustomSearchVisibility";

const {getProductIndexName} = useAlgolia();
const categoryIndexName = useRuntimeConfig().public.algoliaDev.categoryIndexName;
const algolia = useAlgoliaRef();
const {insightsSettings, addSearchHistory} = useAlgolia();
const {isCustomSearchVisible} = useCustomSearchVisibility();

const props = defineProps<{
    type?: string;
}>();

const emits = defineEmits<{
    (e: "click-away"): void;
}>();

// True when the search bar is active and user can type in the search field
const active = inject("searchActive", ref(false));
const mobileSearchOpen = inject("mobileSearchOpen", ref(false));

// Reference to the search container
const searchContainer = ref(null);
const searchInput = ref();
const typingQuery = ref("");

const hideSearch = computed(() => {
    return props.type !== 'mobile' && isCustomSearchVisible();
})

watch(active, (value) => {
    const {focused} = useFocus(searchInput);

    focused.value = value;
});

const showSuggest = computed(() => {
    return typingQuery.value.length >= 1 && active.value;
});

if (process.client) {
    onClickOutside(searchContainer, () => {
        active.value = false;
        emits("click-away");
    });
}

onBeforeRouteLeave(() => {
    active.value = false;
    isSideMenuOpened.value = false;
    mobileSearchOpen.value = false;
    typingQuery.value = "";
});

const {enter} = useMagicKeys({target: searchInput});

const isSideMenuOpened = inject("isSideMenuOpened", ref(false));

watch(enter, (value) => {
    if (!value) return;

    isSideMenuOpened.value = false;

    active.value = false;
    navigateTo("/search?query=" + typingQuery.value);
});

function getRelativeUrl(fullUrl) {
    const url = new URL(fullUrl)
    return url.pathname;
}


</script>

<template>
<div
    ref="searchContainer"
    class="relative group lg:pr-0 transition duration-300 inline-block w-full box-border"
    :class="{'hidden': hideSearch}"
>
    <div class="relative inline-block w-full">
        <input
            ref="searchInput"
            id="mainSearchInput"
            v-model="typingQuery"
            data-test-id="layout-search-input"
            autocomplete="off"
            type="search"
            :class="{
                'bg-white text-[16px]': props.type && props.type == 'mobile',
                'bg-brand-lightGray rounded-full': !props.type
            }"
            class="border-transparent h-[35px] font-light px-6 py-1 outline-none box-border w-full text-xs"
            :placeholder="$t('search.searchPlaceholder')"
            @click="active = true"
        />
        <NuxtImg
            loading="lazy"
            src="/search.svg"
            height="15"
            width="15"
            alt="Søg"
            class="absolute hidden md:block right-3 lg:right-6 top-1/2 -translate-y-1/2 pointer-events-none"
        />
    </div>
    <div
        v-if="showSuggest"
        id="searchSuggestItems"
        class="absolute bg-white border-gray-100 border-t-1 left-0 duration-300 mt-2 overflow-hidden shadow-md transition-height w-full md:mt-4 z-10"
    >
        <ais-instant-search :insights="insightsSettings()" :search-client="algolia" :index-name="getProductIndexName()"
                            :future="{preserveSharedStateOnUnmount: true}">
            <ais-configure :hits-per-page.camel="4" :query="typingQuery"
                           filters="visibilities.ca7a8b4761a844be94f9cbe265179d6a:30"/>
            <ais-index :index-name="categoryIndexName">
                <ais-configure :hits-per-page.camel="3" :query="typingQuery" filters="visible:true AND active:true"/>
                <ais-hits :class-names="{
                    'ais-Hits-list': 'flex flex-col list-none p-0 m-0',
                    'ais-Hits-item': 'w-full p-0 m-0 border-b border-b-solid border-b-brand-gray shadow-none'
                }">
                    <template v-slot:item="{ item, sendEvent }">
                        <NuxtLink
                            :key="item.objectID"
                            :to="getRelativeUrl(item.url)"
                            data-test-id="layout-search-suggest-link"
                            class="py-2"
                            @click="[
                              sendEvent('click', item, 'SearchBar: Category Clicked'),
                              typingQuery = ''
                            ]"
                        >
                            <LayoutCategorySuggestSearch :category="item"/>
                        </NuxtLink>
                    </template>
                </ais-hits>
            </ais-index>
            <ais-hits :class-names="{
              'ais-Hits-list': 'flex flex-col list-none p-0 m-0',
              'ais-Hits-item': 'w-full p-0 m-0 border-b border-b-solid border-b-brand-gray shadow-none'
          }">
                <template v-slot:item="{ item, sendEvent }">
                    <NuxtLink
                        :key="item.objectID"
                        :to="getRelativeUrl(item.url)"
                        class="py-2"
                        @click="[
                          sendEvent('click', item, 'SearchBar: Product Clicked'),
                          addSearchHistory(item.objectID, item.__queryID),
                          typingQuery = ''
                        ]"
                    >
                        <ProductSuggestSearch :minimal="false" :product="item"/>
                    </NuxtLink>
                </template>
            </ais-hits>
            <ais-stats>
                <template v-slot="{ nbHits }">
                    <div
                        class="h-11 text-sm p-3 text-center transition bg-gray-100"
                        style="clip-path: inset(0% 0% 0% 0%)"
                    >
                        <NuxtLink
                            v-if="nbHits > 0"
                            :to="`/search?query=${typingQuery}`"
                        >
                            {{ $t('search.resultCount', nbHits, {count: nbHits}) }}
                        </NuxtLink>
                        <div v-else>
                            <span class="font-sans">{{ $t('search.noResults') }}</span>
                        </div>
                    </div>
                </template>
            </ais-stats>
        </ais-instant-search>
    </div>
</div>
</template>
<style>

#mainSearchInput::-webkit-search-decoration,
#mainSearchInput::-webkit-search-cancel-button,
#mainSearchInput::-webkit-search-results-button,
#mainSearchInput::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

@media screen and (max-width: 767px) {
    #mainSearchInput::placeholder {
        font-size: 13px
    }
    #searchSuggestItems {
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }
}

#mainSearchInput::placeholder {
    color: #A8A8A8;
}
</style>
